import React from "react";
import TrackingTag from "components/TrackingTag/TrackingTag";
import styles from "./PageElements.module.scss";
import { motion } from "framer-motion";
import detafel from "../assets/images/de-tafel.jpg";
import deband from "../assets/images/grote-pietenhuis-band.jpg";

const trackTagProps = {
	config: {
		pageName: "speculasies",
		subsite: false,
		chapters: [],
		siteId: 80,
		initialisationCode: 630429,
		initialisationCodeForTest: 630430,
		siteType: "standalone",
		platform: "site",
		broadcaster: "ntr",
		idfa: false,
		program: "sinterklaasjournaal",
		eventType: "home",
	},
	customVars: {
		1: "[site]",
		2: "[ntr]",
		3: "[sinterklaasjournaal]",
		4: "[standalone]",
		6: "[speculasies]",
		7: "[general]",
	},
	settings: {
		name: "speculasies-home",
		level2: 80,
	},
};

const Home = () => {
	return (
		<>
			<motion.div
				className={styles.textblock}
				initial="hidden"
				animate="visible"
				exit={{ opacity: 0, y: "-30vh", transition: { duration: 0.4 } }}
				variants={{ visible: { transition: { staggerChildren: 0.3 } } }}>
				<h2>Een trommel vol Speculasies!</h2>
				<p>
					Speculasies is het programma waarin we vooruitkijken en terugblikken
					op de uitzending van het Sinterklaasjournaal. Aan tafel worden de
					belangrijkste gebeurtenissen en de grootste mysteries van de afgelopen
					week besproken met deskundigen Richard van der Zwets, Yvonne
					Kletsmeijer, Jaap van Stamelen en Melanie Monter. Sidekick Stephanie
					praat ons bij over de inzendingen uit het land, en er is muziek van de
					Grote Pietenhuis Band.
				</p>
				<p>
					Bekijk de afleveringen via de links onderaan de pagina.
				</p>

				<p>
					<strong>BREAKING:</strong> Het is de Grote Pietenhuis Band gelukt om
					te spelen in het Grote Pietenhuis! Neem snel een kijkje <a
						aria-label="Naar de pagina Zingen op de site van het Sinterklaasjournaal"
						target="_blank"
						rel="noopener noreferrer"
						href="https://sinterklaasjournaal.ntr.nl/zingen">
						op de site van het Sinterklaasjournaal
					</a>
					!
				</p>

				<img
					src={deband}
					className={styles.presentator}
					alt="De Grote Pietenhuis Band"
				/>
				<h2>Afleveringen</h2>
				<h3>Aflevering 4</h3>
				<p>
					Heeft Yvonne Kletsmeijer meer informatie over de verdwijning van Sinterklaas? Hoe zit het met de Boekpiet die zich heeft verkleed, en komt het grote boek ooit nog uit de kluis?
				</p>
				<p>
					<a
						aria-label="Naar aflevering 4 op NPO Start"
						target="_blank"
						rel="noopener noreferrer"
						href="https://npo.nl/start/serie/speculasies/seizoen-1/speculasies_2/afspelen">
						Bekijk aflevering 4 op NPO Start
					</a>
				</p>
				<h3>Aflevering 3</h3>
				<p>
					Wat zou toch de code van die kluis kunnen zijn? En wat is er toch aan de hand met Ozosnel?
				</p>
				<p>
					<a
						aria-label="Naar aflevering 3 op NPO Start"
						target="_blank"
						rel="noopener noreferrer"
						href="https://npo.nl/start/serie/speculasies/seizoen-1/speculasies/afspelen">
						Bekijk aflevering 3 op NPO Start
					</a>
				</p>
				<h3>Aflevering 2</h3>
				<p>
					Welke Piet is verkozen tot de leukste Piet. En wie zit er in die
					verkleedkist?
				</p>
				<p>
					<a
						aria-label="Naar aflevering 2 op NPO Start"
						target="_blank"
						rel="noopener noreferrer"
						href="https://npo.nl/start/serie/speculasies/seizoen-1/speculasies_3/afspelen">
						Bekijk aflevering 2 op NPO Start
					</a>
				</p>
				<h3>Aflevering 1</h3>
				<p>
					Het belangrijkste onderwerp: gaat Dieuwertje Blok stoppen met het
					Sinterklaasjournaal?
				</p>
				<p>
					<a
						aria-label="Naar aflevering 1 op NPO Start"
						target="_blank"
						rel="noopener noreferrer"
						href="https://npo.nl/start/serie/speculasies/seizoen-1/speculasies_1/afspelen">
						Bekijk aflevering 1 op NPO Start
					</a>
				</p>
				<img
					src={detafel}
					className={styles.presentator}
					alt="De tafel van Speculasies"
				/>
			</motion.div>
			<TrackingTag tag={trackTagProps} />
		</>
	);
};

export default Home;
